<template>
    <Layout>
        <div class="rwt-portfolio-details rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <div class="inner">
                            <div class="details-list">
                                <!-- Main Image Slider -->
                                <vue-slick-carousel :dots="true" :arrows="true" :infinite="true" :speed="500"
                                    :slidesToShow="1" :slidesToScroll="1" class="radius w-100">
                                    <div v-for="(photo, index) in portfolio.photos" :key="index">
                                        <img :src="photo.image" alt="Property Image" class="radius w-100" />
                                    </div>
                                </vue-slick-carousel>

                                <!-- Portfolio Title & Address -->
                                <div class="row mt--40 row--30">
                                    <div class="col-lg-12">
                                        <h4 class="title">{{ portfolio.title }}</h4>
                                        <h6 class="address">{{ portfolio.address }}, {{ portfolio.city }}, {{
                                            portfolio.country }}</h6>
                                        <h6 class="address">{{ portfolio.type }} for {{ portfolio.status_type }}</h6>
                                    </div>
                                </div>

                                <!-- Portfolio Description & Amenities -->
                                <div class="row mt--30">
                                    <div class="col-lg-6">
                                        <div class="content-left">
                                            <p class="description">{{ portfolio.description }}</p>

                                            <h5>Amenities</h5>
                                            <ul>
                                                <li v-for="(amenity, index) in portfolio.amenities" :key="index">
                                                    <i class="fas fa-check-circle"></i> {{ amenity }}
                                                </li>
                                            </ul>

                                            <!-- Additional Information (Room, Bath, etc.) -->
                                            <div class="additional-info mt--30">
                                                <div class="info-item">
                                                    <strong>Rooms:</strong> {{ portfolio.room }}
                                                </div>
                                                <div class="info-item">
                                                    <strong>Bathrooms:</strong> {{ portfolio.bath }}
                                                </div>
                                                <div class="info-item">
                                                    <strong>Hall:</strong> {{ portfolio.hall }}
                                                </div>
                                                <div class="info-item">
                                                    <strong>Square Footage:</strong> {{ portfolio.sqft }} sqft
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Right Section: Price & Booking Button -->
                                    <div class="col-lg-6 mt_md--30 mt_sm--30">
                                        <div class="content-right">
                                            <h4 class="price">{{ portfolio.currency }} {{ formattedPrice() }} <span
                                                    v-if="portfolio.status_type == 'SHORTLET'">Per Night</span></h4>
                                            <a class="btn-default btn-large round" :href="portfolio.googlemapslink"
                                                target="_blank">
                                                View on Google Maps
                                            </a>

                                            <div class="view-btn mt--30" @click="showVR = !showVR">
                                                <a class="btn-default btn-large round" style="cursor: pointer;">
                                                    Show Virtual Tour
                                                </a>
                                            </div>

                                            <div class="virtual-tour mt--30" v-if="showVR">
                                                <iframe :src="portfolio.vr" width="100%" height="500" frameborder="0"
                                                    allowfullscreen></iframe>
                                            </div>

                                            <div class="view-btn mt--30">
                                                <a class="btn-default btn-large round" :href="portfolio.floor_plan"
                                                    target="_blank">
                                                    View Floor Plan
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Image Gallery Thumbnails -->
                                <div class="portfolio-gallery mt--40">
                                    <h5>Image Gallery</h5>
                                    <div class="gallery mt--30">
                                        <div class="row">
                                            <div class="col-md-4" v-for="(photo, index) in portfolio.photos.slice(1)"
                                                :key="index">
                                                <div class="thumbnail">
                                                    <img class="radius w-100" :src="photo.image"
                                                        alt="Property Thumbnail" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout';
import PortfolioItemMixin from '../mixins/PortfolioItemMixin';
import VueSlickCarousel from 'vue-slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default {
    name: 'PortfolioDetailsPage',
    components: {
        Layout,
        VueSlickCarousel
    },
    mixins: [PortfolioItemMixin],
    data() {
        return {
            id: this.$route.params.id,
            portfolio: {},
            showVR: false
        }
    },
    methods: {
        getPortfolio() {
            this.portfolio = JSON.parse(localStorage.getItem('property'));
            console.log(this.portfolio);
            // this.portfolio = this.portfolioItems.find(item => item.id == portfolioId);
        },
        formattedPrice() {
            // Format price with thousands separator
            return new Intl.NumberFormat('en-US').format(this.portfolio.price);
        }
    },
    created() {
        this.getPortfolio();
    }
}
</script>