<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12" v-for="(service, index) in serviceList" :key=index>
            <div :class="`service ${serviceStyle} ${checkTextAlign}`" data-aos="fade-up" data-aos-delay="70">
                <div class="icon">
                    <Icon :name="service.icon" :class="`size-${iconSize}`" />
                </div>
                <div class="content">
                    <h4 class="title w-600">
                        <router-link to="/about" v-html="service.title" />
                    </h4>
                    <p class="description b1 color-gray mb--0" v-html="service.description" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
    name: 'ServiceOne',
    components: { Icon },
    props: {
        serviceStyle: {
            type: String
        },
        iconSize: {
            type: String | Number,
            default: 62
        },
        textAlign: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            serviceList: [
                {
                    icon: 'activity',
                    title: 'Short-Let',
                    description: ' Ideal for travelers, business professionals, or those in transition. We provide fully furnished, hassle-free short-term rentals in top locations.'
                },
                {
                    icon: 'cast',
                    title: 'Rental',
                    description: 'Whether you\'re moving to a new area or seeking a temporary place to live, we offer a range of rental properties that cater to different budgets and preferences.'
                },
                {
                    icon: 'map',
                    title: 'Sale',
                    description: 'Explore a variety of homes, apartments, and commercial properties available for sale. Beeinvisible offers expert guidance for first-time buyers and seasoned investors alike.'
                },
            ]
        }
    },
    computed: {
        checkTextAlign() {
            if (this.textAlign === 'left') return 'text-start';
            else if (this.textAlign === 'center') return 'text-center';
            else if (this.textAlign === 'end') return 'text-end';
            else return '';
        }
    }
}
</script>