<template>
    <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
        <div class="accordion" :id="id">
            <div class="accordion-item card" v-for="(content, index) in accordionContent">
                <h2 class="accordion-header card-header" :id="`heading${content.id}`">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapse${content.id}`"
                        :aria-expanded="index === activeCollapse ? 'true' : 'false'"
                        :aria-controls="`collapse${content.id}`" @click="activeCollapse = index">
                        {{ content.title }}
                        <i class="collapse-icon" />
                    </button>
                </h2>
                <div :id="`collapse${content.id}`" class="accordion-collapse collapse" :class="{ 'show': index === 0 }"
                    :aria-labelledby="`heading${content.id}`" :data-bs-parent="`#${id}`">
                    <div class="accordion-body card-body">
                        {{ content.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Accordion',
    props: {
        id: {
            type: String,
            default: 'accordionExample'
        },
        accordionStyleClass: {
            type: String
        },
        accordionContent: {
            type: Array,
            default: function () {
                return [
                    {
                        id: 'One',
                        title: 'What types of properties does Beeinvisible offer?',
                        description: "Beeinvisible offers a wide range of properties, including short-let apartments, rental homes, and commercial spaces, as well as properties for sale. Whether you’re looking for a temporary stay, a long-term rental, or an investment opportunity, we have options to suit your needs."
                    },
                    {
                        id: 'Two',
                        title: 'How do I book a short-let property?',
                        description: 'Booking a short-let property with Beeinvisible is simple and quick. Browse our listings online, select your desired property, and submit a booking request. Our team will confirm availability and guide you through the payment and check-in process.'
                    },
                    {
                        id: 'Three',
                        title: 'Can Beeinvisible help me sell my property?',
                        description: 'Yes, Beeinvisible offers property sales services to help you sell your property efficiently and at the best market price. Our team of experts will handle marketing, viewings, and negotiations, ensuring a smooth and hassle-free process.'
                    },
                    {
                        id: 'Four',
                        title: 'What areas does Beeinvisible cover?',
                        description: 'Beeinvisible focuses on prime locations in major cities and suburban areas, providing properties that meet diverse preferences and budgets. Check our website or contact us to explore properties available in your desired area.'
                    },
                ]
            }
        }
    },
    data() {
        return {
            activeCollapse: 0
        }
    }
}
</script>