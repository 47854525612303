<template>
    <div class="rn-card box-card-style-default" :class="{ 'card-list-view': blogPostStyle === 'list' }">
        <div class="inner">
            <div class="thumbnail">
                <div style="cursor: pointer;" class="image" @click="getPropertyDetails(blog)">
                    <img class="w-100" :src="blog.photos[0]['image']" :alt="blog.title">
                </div>
            </div>
            <div class="content">
                <ul v-if="blogPostStyle === 'card'" class="rn-meta-list">
                    <li>
                        <router-link :to="''">
                            {{ blog.status_type }}
                        </router-link>
                    </li>
                    <li class="separator">/</li>
                    <li>{{ blog.type }}</li>
                </ul>
                <h4 class="title">
                    <router-link :to="`/listing-details/${blog.title}`">
                        {{ blog.title }}
                    </router-link>
                </h4>
                <ul v-if="blogPostStyle === 'list'" class="rn-meta-list">
                    <li>
                        <router-link :to="''">
                            {{ blog.status_type }}
                        </router-link>
                    </li>
                    <li class="separator">/</li>
                    <li>{{ blog.type }}</li>
                </ul>
                <!-- <p v-if="blogPostStyle === 'list'" class="descriptiion">
                    {{ blog.excerpt }}
                </p> -->
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../../src/router';
export default {
    name: 'BlogPost',
    props: {
        blog: {},
        blogPostStyle: {
            type: String,
            default: 'card'
        }
    },
    methods: {
        getPropertyDetails(details) {
            // console.log('here')
            localStorage.setItem('property', JSON.stringify(details));
            router.push('/listing-details/' + details.title);
        }
    }
}
</script>