<template>
    <div>
        <!-- <div class="row row--15">
            <div class="col-lg-12">
                <ul class="nav nav-pills rwt-portfolio-filter filter-button-default liststyle mb--20"
                    id="pills-tab"
                    role="tablist"
                    data-aos="fade-up">
                    <li class="nav-item list-item"
                        role="presentation"
                        v-for="(filter, index) in filters"
                        :key="`tab-button-${filter.id}`">
                        <button class="nav-link"
                                :class="{'active': index === 0}"
                                :id="`pills-${filter.text}-tab`"
                                data-bs-toggle="pill"
                                :data-bs-target="`#pills-${filter.text}`"
                                type="button"
                                role="tab"
                                :aria-controls="`pills-${filter.text}`"
                                :aria-selected="index === activeTab ? 'true' : 'false'"
                                @click="activeTab = index">
                            {{ filter.text }}
                        </button>
                    </li>
                </ul>
            </div>
        </div> -->
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade" :class="{ 'show active': index === 0 }" :id="`pills-${filter.text}`"
                role="tabpanel" :aria-labelledby="`pills-${filter.text}-tab`" v-for="(filter, index) in filters"
                :key="`tab-pane-${filter.id}`">
                <div class="row row--15">
                    <template v-for="(portfolio, index) in listings">
                        <div :class="`${column} portfolio`" data-aos="fade-up">
                            <div class="rwt-card">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <figure class="card-image">
                                            <div style="cursor: pointer;" :to="`/listing-details/${portfolio.title}`"
                                                @click="getPropertyDetails(portfolio)">
                                                <img class="img-fluid" :src="portfolio.photos[0].image"
                                                    alt="listing-01" />
                                            </div>
                                        </figure>
                                        <div style="cursor: pointer;" :to="`/listing-details/${portfolio.title}`"
                                            class="rwt-overlay" @click="getPropertyDetails(portfolio)"></div>
                                    </div>
                                    <div class="content">
                                        <h5 class="title mb--10">
                                            <div style="cursor: pointer;" :to="`/listing-details/${portfolio.title}`"
                                                @click="getPropertyDetails(portfolio)">
                                                {{ portfolio.title }}
                                            </div>
                                        </h5>
                                        <span class="subtitle b2 text-capitalize">{{ portfolio.type }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <!-- <template v-if="filter.text !== 'all' && filter.text === portfolio.category"
                        v-for="(portfolio, index) in portfolioData">
                        <div :class="`${column} portfolio`">
                            <div class="rwt-card">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <figure class="card-image">
                                            <router-link :to="`/portfolio-details/${portfolio.id}`">
                                                <img class="img-fluid" :src="portfolio.portfolioImage"
                                                    alt="Portfolio-01" />
                                            </router-link>
                                        </figure>
                                        <router-link :to="`/portfolio-details/${portfolio.id}`"
                                            class="rwt-overlay"></router-link>
                                    </div>
                                    <div class="content">
                                        <h5 class="title mb--10">
                                            <router-link :to="`/portfolio-details/${portfolio.id}`">
                                                {{ portfolio.title }}
                                            </router-link>
                                        </h5>
                                        <span class="subtitle b2 text-capitalize">{{ portfolio.category }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template> -->
                </div>
            </div>
        </div>
        <div class="row row--15" v-if="nextPage">
            <div class="col-lg-12 text-center">
                <div class="rwt-load-more text-center mt--60" data-aos="fade-up">
                    <a :class="buttonClass" style="cursor: pointer;" @click="getMoreProperties()">
                        Load More
                        <Icon name="loader" icon-class="icon" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../../router';
import Icon from '../../icon/Icon'

export default {
    name: 'Portfolio',
    components: { Icon },
    props: {
        portfolioData: {},
        buttonClass: {
            type: String,
            default: 'btn btn-default btn-large btn-icon'
        },
        column: {
            type: String,
            default: 'col-lg-6 col-md-6 col-sm-12 col-12 mt--30'
        }
    },
    data() {
        return {
            url: process.env.VUE_APP_BASE_URL,
            listings: [],
            nextPage: null,
            filters: [
                {
                    id: 1,
                    text: 'all',
                },
                {
                    id: 2,
                    text: 'design',
                },
                {
                    id: 3,
                    text: 'art',
                },
                {
                    id: 4,
                    text: 'development',
                }
            ],
            activeFilter: '',
            activeTab: 0
        }
    },
    mounted() {
        this.getProperties();
    },
    methods: {
        getProperties() {

            this.axios.get(this.url + "api/v1/properties/").then(response => {
                // console.log(response.data)
                this.listings = response.data.results;
                if (response.data.links.next != null) {
                    this.nextPage = response.data.links.next;
                } else {
                    this.nextPage = null;
                }
                // console.log(this.listings)
            }).catch(err => {
                // console.log(err);
            });
        },
        getMoreProperties() {

            if (this.nextPage != null) {

                this.axios.get(this.nextPage).then(response => {
                    // console.log(response.data)
                    this.listings = [
                        ...this.listings,
                        ...response.data.results,
                    ];
                    if (response.data.links.next != null) {
                        this.nextPage = response.data.links.next;
                    } else {
                        this.nextPage = null;
                    }
                    // console.log(this.listings)
                }).catch(err => {
                    // console.log(err);
                });
            }
        },
        getPropertyDetails(details) {
            // console.log('here')
            localStorage.setItem('property', JSON.stringify(details));
            router.push('/listing-details/' + details.title);
        }
    }
}
</script>