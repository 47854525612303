<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1">
        <iframe title="Our Location"
            src="//www.google.com/maps/embed/v1/place?q=place_id:ChIJhVkJ6tUAdkgRlGVb4ExnZYs&amp;key=AIzaSyBpuLDyB4by1-_k8D7JfwVB_MGXk_1h0z4"
            allowfullscreen=""></iframe>
    </div>
    <!-- End Google MAp -->
</template>

<script>
export default {
    name: 'GoogleMap'
}
</script>