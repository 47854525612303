<template>
    <Layout header-class="header-left-align" nav-alignment="left" :footer-style="2"
        buy-button-class="btn-default btn-small">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-3 height-850">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                        <div class="inner text-left">
                            <h1 class="title theme-gradient" data-aos="fade-up" data-aos-delay="100">Bee
                                <br />Invisible
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="130">
                                Find your perfect home, rental or short-let on Beeinvisible. We have a wide array of
                                properties for your delight.
                            </p>
                            <div class="button-group mt--30 mt_sm--20" data-aos="fade-up" data-aos-delay="160">
                                <router-link class="btn-default btn-medium btn-icon" to="/portfolio">
                                    View Properties
                                    <Icon name="arrow-right" size="20" icon-class="icon" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="order-1 order-lg-2 col-lg-5" data-aos="fade-up" data-aos-delay="100">
                        <div class="thumbnail">
                            <img src="../../assets/images/about/bee1.jpeg" alt="Banner Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <Separator />

        <!-- Start Elements Area -->
        <div class="rwt-tab-area rn-section-gap">
            <div class="container">
                <div class="row mb--40">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="About Beeinvisible" title="What About Our Us?"
                            description="From start to finish, we are here to help you achieve your dreams <br /> and give you the desired comfort."
                            data-aos="fade-up" />
                    </div>
                </div>
                <Tab :tab-data="tabData" />
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator />

        <!-- Start Timeline Area -->
        <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Process" title="Booking Process"
                            description="From start to finish, we are here to help you achieve your dreams <br /> and give you the desired comfort."
                            data-aos="fade-up" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 mt--50">
                        <Timeline :timeline-data="timelineData" :timeline-style="4" />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Timeline Area -->

        <Separator />

        <!-- Start About Area -->
        <div class="rwt-about-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-5">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="../../assets/images/about/faq.jpeg" alt="About Images">
                        </div>
                    </div>
                    <div class="col-lg-7 mt_md--40 mt_sm--40">
                        <div class="content">
                            <SectionTitle subtitle="FREQUENTLY ASKED QUESTIONS" title="Beeinvisible Properties."
                                data-aos="fade-up" />
                            <accordion id="accordionExampleOne" data-aos="fade-up" data-aos-delay="60" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End About Area -->

        <Separator />

        <!-- Start Pricing Area -->
        <!-- <div class="rwt-pricing-area rn-section-gap">
            <div class="container">
                <div class="row mb--35">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Our Pricing Plan" title="Business Pricing Plan"
                            description="We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials"
                            data-aos="fade-up" />
                    </div>
                </div>
                <Pricing :pricing-data="pricingData" :pricing-style="3" />
            </div>
        </div> -->
        <!-- End Pricing Area -->

        <Separator />

        <!-- Start Blog Area -->
        <div class="blog-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Latest Listings" title="Our Latest Listings"
                            description="We provide the best properties for all for <br /> short-lets, rent and for sale."
                            data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30" data-aos="slide-up" data-aos-duration="800"
                        :data-aos-delay="100 + index" v-for="(item, index) in listings" :key="index">
                        <BlogPost :blog="item" />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Blog Area -->
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Icon from '../../components/icon/Icon'
import Separator from '../../components/elements/separator/Separator'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import Tab from '../../components/elements/tab/Tab'
import Timeline from '../../components/elements/timeline/Timeline'
import Accordion from '../../components/elements/accordion/Accordion'
import Pricing from '../../components/elements/pricing/Pricing'
import BlogPostMixin from '../../mixins/BlogPostMixin'
import BlogPost from '../../components/blog/BlogPost'

export default {
    name: 'Business',
    components: { BlogPost, Pricing, Accordion, Timeline, Tab, SectionTitle, Separator, Icon, Layout },
    mixins: [BlogPostMixin],
    data() {
        return {
            url: process.env.VUE_APP_BASE_URL,
            listings: [],
            tabData: {
                tabImage: 'tab.jpg',
                tabContent: [
                    {
                        id: 1,
                        menu: 'short-lets',
                        content: `<p>Looking for a flexible and hassle-free stay that feels like home? At Beeinvisible, we provide a wide range of short-let properties to suit your unique needs, whether you're on a business trip, vacation, or relocating temporarily. Our fully furnished apartments and homes are situated in prime locations, offering stylish interiors, modern amenities, and hotel-like services for your ultimate convenience. Experience a comfortable, stress-free stay with flexible terms designed to fit your schedule and lifestyle.</p>`,
                    },
                    {
                        id: 2,
                        menu: 'rentals',
                        content: `<p>Discover the perfect rental property with Beeinvisible, where finding your ideal home or workspace has never been easier. We offer a carefully curated selection of houses, apartments, and commercial spaces in the most sought-after locations, catering to various lifestyles and budgets. Our expert team ensures a seamless renting process, guiding you every step of the way to match you with a property that feels just right. Whether you're upgrading your space, moving to a new area, or seeking a temporary solution, Beeinvisible has you covered.</p>`,
                    },
                    {
                        id: 3,
                        menu: 'sales',
                        content: `<p>Turn your vision of property ownership into a reality with Beeinvisible, your trusted partner in real estate. Explore our extensive listings of houses, apartments, and commercial properties for sale, each chosen for its value, location, and potential. From first-time homebuyers to seasoned investors, we provide personalized guidance to help you navigate the market and find the perfect property. With Beeinvisible, you can invest confidently, knowing that our expertise and commitment to excellence will make your property journey rewarding and stress-free.</p>`,
                    }
                ]
            },
            timelineData: [
                {
                    id: '1',
                    title: 'Identify',
                    description: 'Identify the property you are interested in.',
                },
                {
                    id: '2',
                    title: 'View',
                    description: 'View more details about the property to see the fit.',
                },
                {
                    id: '3',
                    title: 'Explore',
                    description: 'Explore the property with out great VR Experience.',
                },
                {
                    id: '4',
                    title: 'Book',
                    description: 'Easily place your booking on our web platform.',
                },
            ],
            pricingData: [
                {
                    title: 'Free',
                    currency: '$',
                    price: '29',
                    subtitle: 'USD Per Month',
                    features: [
                        { title: '5 PPC Campaigns' },
                        { title: 'Digital Marketing' },
                        { title: 'Marketing Agency' },
                        { title: 'Seo Friendly' },
                        { title: 'UI/UX designs' },
                    ],
                    btn: {
                        text: 'Purchase Now',
                        link: '#'
                    }
                },
                {
                    title: 'Business',
                    currency: '$',
                    price: '50',
                    subtitle: 'USD Per Month',
                    features: [
                        { title: '5 PPC Campaigns' },
                        { title: 'Keep 100% Royalties' },
                        { title: 'App Development' },
                        { title: 'Seo Friendly' },
                        { title: 'UI/UX designs' },
                    ],
                    btn: {
                        text: 'Purchase Now',
                        link: '#'
                    },
                    isActive: true
                }
            ],
        }
    },
    mounted() {
        this.getLatestProducts();
    },
    methods: {
        getLatestProducts() {

            this.axios.get(this.url + "api/v1/properties/").then(response => {
                // console.log(response.data)
                this.listings = response.data.results.slice(0, 3);
                // console.log(this.listings)
            }).catch(err => {
                // console.log(err);
            });
        },
    }
}
</script>