<template>
    <div class="about-area about-style-4 rn-section-gap">
        <div class="container">
            <div class="row row--40 align-items-center">
                <div class="col-lg-6">
                    <Video :video-data="videoData" column="col-12" />
                </div>
                <div class="col-lg-6 mt_md--40 mt_sm--40">
                    <div class="content">
                        <div class="inner">
                            <h3 class="title">Bee Invisible specializes in <br /> mainly <strong>Real Estate.</strong>
                            </h3>
                            <ul class="feature-list">
                                <li>
                                    <Icon name="check" size="20" icon-class="icon" />
                                    <div class="title-wrapper">
                                        <h4 class="title">Expert Guidance</h4>
                                        <p class="text">
                                            Our team of real estate professionals provides you with the knowledge and
                                            insights needed to make informed decisions.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <Icon name="check" size="20" icon-class="icon" />
                                    <div class="title-wrapper">
                                        <h4 class="title">Wide Selection</h4>
                                        <p class="text">
                                            We offer a diverse range of properties that meet various needs, whether for
                                            rent, sale, or short-term stays.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <div class="about-btn mt--30">
                                <router-link class="btn-default" to="/about">About Beeinvisible</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '../../icon/Icon'
import Video from "../video/Video";

export default {
    name: 'AboutFour',
    components: { Video, Icon },
    props: {
        image: {}
    },
    data() {
        return {
            videoData: [
                {
                    thumb: this.image,
                    src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk',
                }
            ]
        }
    }
}
</script>